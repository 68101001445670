@use "../utils/functions/"as *;
@use "../utils/mixins/"as *;

.footer {
  &__inner {
    padding: 1.85rem 0;
  }

  &__top {
    position: relative;
    padding-bottom: 1.7rem;

    &::before {
      content: "";
      position: absolute;
      bottom: 10px;
      width: 100%;
      height: 1px;
      background-color: rgba(44, 46, 53, 0.12);
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
    font-size: 16px;

    @include media(mobile-m) {
      flex-direction: column;
      text-align: center;
      gap: 16px;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }

  &__item {
    &:not(:last-child) {
      margin-right: 1.6rem;

      @include media(mobile-l) {
        margin-right: 16px;
      }

      @include media(mobile-m) {
        margin: 0;
      }
    }
  }

  &__link {
    transition: color 0.3s linear;

    &:hover {
      color: var(--color-blue-800);
    }
  }
}