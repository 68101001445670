@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.gallery {
  min-height: 821px;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;

  @include media(tablet-wide) {
    min-height: 400px;
    background-size: cover;
  }

  @include media(mobile-m) {
    min-height: 250px;
  }
}
