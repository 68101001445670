/* stylelint-disable declaration-no-important */
@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

*[class].btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 12px;
  padding: 8px 22px;
  width: 100%;
  max-width: fit-content;
  height: max-content;
  font-weight: var(--fw-700);
  font-size: 16px;
  line-height: 1.25;
  color: var(--color-pink-100);
  background-color: var(--color-blue-800);
  transition-property: background-color, color, border-color;
  transition-duration: 0.3s;

  &:hover,
  &:focus {
    border-color: var(--color-blue-800);
    color: var(--color-blue-800);
    background-color: var(--color-pink-100);
  }

  &.formsapp-button {
    font-family: var(--font-family-primary);
    margin: 0;
    background-color: var(--color-blue-800) !important;

    &:hover,
    &:focus {
      border-color: var(--color-blue-800) !important;
      color: var(--color-blue-800) !important;
      background-color: var(--color-pink-100) !important;
    }
  }

  &--reverse {
    border-color: var(--color-blue-800);
    color: var(--color-blue-800);
    background-color: var(--color-pink-100);

    &:hover,
    &:focus {
      color: var(--color-pink-100);
      background-color: var(--color-blue-800);
    }
  }

  &--big {
    max-width: 190px;
  }

    &--slider {
    position: relative;
    z-index: 2;
    padding: 0;
    border: 0;
    border-radius: 50%;
    border-color: transparent;
    background-color: transparent;
    color: var(--color-white);
    height: 32px;
    max-width: 32px;


    &::before {
      @include mask;
      width: 32px;
      height: 32px;
      mask-image: url("../images/icons/arrow.svg");
      mask-position: 0 0;
      background-color: var(--color-dark-900);
    }

    &:hover,
    &:focus {
      border-color: transparent;
      &::before {
        background-color: var(--color-blue-800);
      }
    }
  }
}


  // .btn {
  //   &.swiper-button-disabled {
  //     background-color: var(--color-gray-600);
  //     pointer-events: none;
  //   }

  //   &--prev {
  //     left: 8px;
  //     scale: -1 1;
  //   }

  //   &--next {
  //     right: 8px;
  //   }

  //   @include media(mobile-m) {
  //     display: none;
  //   }
  // }