@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.contact {
  $root: &;

  padding: 1.8rem 0;

  &__content {
    margin-bottom: 2.4rem;
  }

  &__inner {
    border-radius: var(--radius-big);
    padding: 3rem 1rem;
    width: 100%;
    max-width: 1100px;
    box-shadow: 0 0 60px 0 rgba(188, 247, 255, 0.12);
    text-align: center;
    color: var(--color-white);
    background-color: var(--color-blue-800);
    margin-inline: auto;

    h2 {
      margin-bottom: 16px;
    }

    p {
      line-height: 1;
    }
  }

  &__form {
    width: 100%;
    max-width: 556px;
    margin-inline: auto;

    label {
      display: block;
      text-align: left;

      span {
        display: block;
        margin-bottom: 6px;
        font-size: 16px;
        line-height: 1.75;
      }

      &:not(:last-of-type) {
        margin-bottom: 24px;
      }

      &:last-of-type {
        margin-bottom: 18px;
      }
    }

    textarea,
    input {
      border: 1px solid rgba(255, 243, 249, 0.36);
      border-radius: 8px;
      padding: 12px 16px;
      width: 100%;
      height: 42px;
      font-size: 14px;
      line-height: 1.2;
      color: var(--color-white);
      background-color: var(--color-blue-800);

      &::placeholder {
        color: rgba(255, 243, 249, 0.36);
      }
    }

    textarea {
      height: 96px;
      resize: none;
    }
  }

  .btn {
    max-width: 109px;
    margin-inline: auto;

    &:hover,
    &:focus {
      background-color: var(--color-pink-400);
    }
  }
}
