/* stylelint-disable declaration-no-important */
@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

.article {
  padding: 2.4rem 0 4.3rem;

  &__head {
    margin-bottom: 2.8rem;
    min-height: 415px;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    @include media(mobile-l) {
      margin-bottom: 1.5rem;
      min-height: 350px;
    }

    @include media(mobile-m) {
      min-height: 200px;
    }
  }

  &__descr {
    margin: 0 auto 16px;
    width: 100%;
    max-width: 800px;

    h2 {
      margin-bottom: 16px;
      text-align: center;
    }
  }

  img {
    overflow: hidden;
    margin-bottom: 24px;
    border-radius: var(--radius-main);
  }

  h1 {
    padding: 0 16px;
    margin: 0 auto 1.8rem;
    text-align: center;
    text-transform: capitalize;
  }

  h2,
  p {
    margin-bottom: 24px;
    letter-spacing: 0.02em;
  }

  p {
    margin-bottom: 36px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  h2 {
    font-size: clamp(18px, 1rem + 0.5556vi, 24px);
    line-height: 1.67;
  }

  ul {
    margin-bottom: 36px;
    padding-left: 15px;
  }

  li {
    list-style-type: disc;
    letter-spacing: 0.02em;
  }

  @include media(mobile-l) {
    padding: 1.4rem 0 2.3rem;

    br {
      display: none;
    }
  }
}
