@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.projects {
  &__inner {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 2.85rem;

    @include media(tablet) {
      grid-template-columns: 1fr;
      gap: 30px;
    }
  }

  &__content {
    width: 100%;
    max-width: 442px;
    transform: translateY(35px);

    .sub-text {
      margin-bottom: 24px;
      font-weight: var(--fw-300);
    }

    @include media(tablet) {
      max-width: 100%;
      transform: none;
    }
  }

  @include media(tablet) {
    padding: 2rem 0;
  }
}
