@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.reviews {
  &__inner {
    display: flex;
    align-items: flex-end;
    gap: 24px;

    @include media(mobile-l) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__slider {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 783px;
  }

  &__item {
    display: flex;
    min-height: 518px;
    gap: 2.4rem;

    @include media(mobile-l) {
      min-height: 250px;
      gap: 24px;

      img {
        aspect-ratio: 12/9;
      }
    }

    @include media(mobile-s) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__descr {
    align-self: center;
    transform: translateY(-23px);

    p {
      line-height: 1;
    }

    @include media(mobile-l) {
      padding-bottom: 16px;
      transform: none;
    }
  }

  &__autor {
    display: flex;
    align-items: center;
    margin-bottom: 23px;
    gap: 13px;

    @include media(mobile-s) {
      justify-content: center;
    }
  }

  &__btns {
    position: absolute;
    left: 34%;
    bottom: 0;
    z-index: 2;
    display: flex;
    transform: translateX(30%);
    gap: 46px;

    @include media(tablet) {
      left: 70%;
      transform: translateX(70%);
    }

    @include media(mobile-l) {
      transform: translateX(20%);
    }

    @include media(mobile-s) {
      position: relative;
      left: 0;
      justify-content: center;
      transform: none;
    }

    @include media(mobile-s) {
      gap: 24px;
    }
  }

  h3 {
    margin-bottom: 0;
    font-weight: var(--fw-500);
    font-size: clamp(16px, 0.8333rem + 0.7407vi, 24px);
    line-height: 1;
  }

  h2 {
    margin-bottom: 7rem;

    @include media(mobile-l) {
      margin-bottom: 2rem;
    }
  }

  .btn {
    &.swiper-button-disabled {
      opacity: 0;
      pointer-events: none;
    }

    &--prev {
      scale: -1 1;
    }
  }
}
