@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.nav {
  $root: &;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  &__list {
    display: flex;
    align-items: center;
    margin-right: auto;

    @include media(tablet-wide) {
      margin: auto;
    }

     @include media(tablet) {
      margin: 0;
    }
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: 1.6rem;

      @include media(tablet-wide) {
        margin-right: 15px;
      }
    }

    @include media(tablet) {
      margin-bottom: 30px;
    }
  }

  &__link {
    font-size: 16px;
    line-height: 1.25;
    transition: color 0.3s linear;

    &:hover {
      color: var(--color-blue-800);
    }

    &.true {
      color: var(--color-blue-800);
    }
  }

  &__btns {
    display: flex;
    gap: 24px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 3.4rem;

    a {
      &:not(.logo) {
        font-size: 16px;
        line-height: 1.25;
      }
    }

    @include media(mobile-m) {
      margin-bottom: 16px;
      gap: 16px;
    }
  }

  &--footer {
    #{$root}__list {
      margin-right: 13px;
      padding-top: 6px;
      display: block;
      columns: 2;
      gap: 3.5rem;

      @include media(mobile-m) {
        margin: 0;
      }
    }

    #{$root}__item {
      &:not(:last-of-type) {
        margin: 0 0 26px 0;
      }
    }

    @include media(mobile-m) {
      flex-direction: column;
      justify-content: center;
    }
  }
}
