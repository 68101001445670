@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.service {
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.25rem;

    h2 {
      margin-bottom: 0;
    }

    @include media(mobile-m) {
      flex-direction: column;
      gap: 24px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 2.4rem;

    @include media(desktop) {
      gap: 24px;
    }

    @include media(tablet-wide) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    border: 1px solid var(--color-dark-900);
    border-radius: 8px;
    height: max-content;

    img {
      border-radius: 8px;
    }

    &:not(:first-child) {
      display: flex;
      gap: 10px;

      @include media(mobile-l) {
        flex-direction: column;

        img {
          width: 100%;
        }
      }
    }

    &:first-child {
      grid-row: 2 span;
      max-width: 566px;

      .service__descr {
        margin-bottom: 1.8rem;
        padding: 27px 34px 0;

        @include media(mobile-l) {
          padding: 16px;
        }
      }

      img {
        border-radius: 0 0 8px 8px;
      }

      @include media(desktop) {
        align-self: center;
      }

      @include media(tablet-wide) {
        grid-row: auto;
        max-width: 100%;

        img {
          width: 100%;
        }
      }
    }

    &:last-child {
      img {
        border-radius: 8px 0 0 8px;
      }

      .service__descr {
        padding: 38px 0 0 24px;

        @include media(mobile-l) {
          padding: 16px;
        }
      }

      @include media(mobile-l) {
        flex-direction: column-reverse;
      }
    }
  }

  &__descr {
    padding: 26px 0 22px 36px;

    @include media(mobile-l) {
      padding: 16px;
    }
  }

  .btn {
    max-width: 190px;
  }

  h3 {
    margin-bottom: 24px;
  }

  @include media(tablet-wide) {
    br {
      display: none;
    }
  }
}
