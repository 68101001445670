@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

h1,
h2,
h3 {
  overflow: hidden;
  margin-bottom: 1.8rem;
  font-weight: var(--fw-700);
  font-size: clamp(28px, 1.5rem + 1.6667vi, 48px);
  line-height: 1.27;
}

h2 {
  margin-bottom: 2.4rem;
  font-size: clamp(24px, 1.3333rem + 1.0185vi, 36px);
  line-height: 1.3;
}

h3 {
  font-size: clamp(18px, 0.9167rem + 0.9259vi, 28px);
}

.sub-text {
  font-size: clamp(16px, 0.8571rem + 0.7143vi, 24px);

  @include media(tablet) {
    br {
      display: none;
    }
  }
}
