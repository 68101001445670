@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.history {
  &__inner {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 2.4rem;

    @include media(desktop) {
      gap: 24px;
    }

    @include media(tablet) {
      grid-template-columns: 1fr;
    }
  }

  &__image {
    justify-self: center;
    overflow: hidden;
    border-radius: var(--radius-main);

    img {
      height: 100%;

      @include media(mobile-m) {
        object-position: center;
        aspect-ratio: 13 / 9;
      }
    }
  }

  &__descr {
    width: 100%;
    max-width: 581px;
    line-height: 1.5;

    li {
      &:not(:last-child) {
        margin-bottom: 1.8rem;
      }
    }

    h3 {
      margin-bottom: 15px;
      font-size: clamp(16px, 0.8571rem + 0.7143vi, 24px);
      line-height: 1.5;
    }

    @include media(tablet) {
      max-width: 100%;

      br {
        display: none;
      }
    }
  }
}
