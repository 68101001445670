/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");

:root {
  --content-width: 1320px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Space Grotesk", sans-serif;

  // font-weight
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;

  // color
  --color-dark-900: #2c2e35;
  --color-white: #fff;
  --color-pink-100: #fff3f9;
   --color-pink-400: #efc7db;
  --color-blue-800: #73729a;
  //  radius
  --radius-main: 8px;

}
