@use "../../utils/functions/"as *;
@use "../../utils/mixins/"as *;

.faq {
  &__item {
    border-radius: var(--radius-main);
    box-shadow: 0 0 0 2px rgba(44, 46, 53, 0.12);

    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }
  }

  details {
    overflow: hidden;
    border-radius: (var(--radius-main));

    p {
      letter-spacing: 0.02em;
    }

    &[open] {
      summary {
        padding-bottom: 2px;

        &::after {
          transform: scaleY(-1);
        }
      }
    }
  }

  summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 48px 24px 32px;
    font-weight: var(--fw-700);
    line-height: 1.3;
    letter-spacing: 0.02em;
    cursor: pointer;
    gap: 16px;

    &::after {
      @include mask;

      position: absolute;
      right: 30px;
      display: block;
      mask-image: url("../images/icons/chevron-down.svg");
      min-width: 32px;
      min-height: 32px;
      background-color: var(--color-dark-900);
      transition: transform 0.3s linear;
    }

    +div {
      padding: 1.7rem 1.6rem 21px 30px;
    }
  }

  h2 {
    margin-bottom: 2.4rem;
  }
}