@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

.news {
  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 2.4rem;
    gap: 2.4rem;

    @include media(mobile-l) {
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
    }

    @include media(mobile-m) {
      grid-template-columns: 1fr;
    }
  }

  &__cards {
    position: relative;

    img {
      overflow: hidden;
      margin-bottom: 18px;
      border-radius: var(--radius-main);
      width: 100%;

      @include media(mobile-m) {
        aspect-ratio: 16 / 9;
      }
    }

    h3 {
      margin-bottom: 7px;
      font-size: clamp(16px, 1.0833rem + 0.1852vi, 20px);
      transition: color 0.3s linear;
    }

    p {
      font-size: 14px;
    }

    a {
      &::before {
        content: "";
        position: absolute;
        inset: 0;
      }
    }

    &:hover {
      h3 {
        color: var(--color-blue-800);
      }
    }
  }

  &--main {
    padding-top: 2.4rem;
  }

  .btn {
    margin: 0 auto;
  }

  h1 {
    text-align: center;
    text-transform: capitalize;
    margin-inline: auto;
  }

  h2 {
    margin-bottom: 2.3rem;
    text-align: center;
  }
}
