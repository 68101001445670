@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.solutions {
  &__grid {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, auto);
    gap: 2.4rem;

    &:not(:last-of-type) {
      margin-bottom: 2.4rem;
    }

    @include media(desktop) {
      gap: 20px;
    }

    @include media(tablet-wide) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media(tablet) {
      grid-template-columns: 1fr;
    }
  }

  &__image {
    overflow: hidden;
    border-radius: 0 0 8px 8px;

    img {
      width: 100%;

      @include media(tablet) {
        aspect-ratio: 16/9;
      }
    }
  }

  &__list {
    width: 100%;
    max-width: 672px;

    @include media(tablet) {
      max-width: 100%;
    }
  }

  &__item {
    h3 {
      margin-bottom: 0;
      font-size: clamp(18px, 0.9167rem + 0.9259vi, 28px);
    }

    span {
      font-weight: var(--fw-700);
      font-size: clamp(18px, 0.9167rem + 0.9259vi, 28px);
    }

    &:first-of-type {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid rgba(44, 46, 53, 0.12);
      padding-bottom: 14px;
      margin-bottom: 19px;
    }
  }
}
