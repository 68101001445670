@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.portfolio {
  &__masonry {
    columns: 3;
    margin-bottom: 2.45rem;
    gap: 2.4rem;

    * {
      break-inside: avoid;
      margin-bottom: 24px;
    }

    @include media(tablet) {
      gap: 16px;

      * {
        margin-bottom: 16px;
      }
    }

    @include media(mobile-l) {
      columns: 1;
    }
  }

  &__list {
    columns: 2;
    gap: 16px;

    @include media(mobile-l) {
      columns: 4;
    }
  }

  &__item {
    border-radius: var(--radius-main);

    h3 {
      font-size: clamp(18px, 1.0835rem + 0.185vi, 20px);
      text-align: center;

      @include media(mobile-m) {
        font-size: 11px;
      }
    }
  }

  .btn {
    margin-inline: auto;
  }

  h2 {
    margin-bottom: 2.3rem;
    text-align: center;
  }

  @include media(tablet) {
    br {
      display: none;
    }
  }
}
