@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.hero {
  $root: &;

  padding: 2.4rem 0;
  margin-inline: auto;

  h1 {
    text-align: center;
  }

  &__descr {
    p {
      font-weight: var(--fw-500);
      text-align: center;
      margin-inline: auto;
    }
  }

  &--services {
    h1 {
      margin-bottom: 24px;
    }
  }
}
