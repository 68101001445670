@use "../../utils/functions/"as *;
@use "../../utils/mixins/"as *;

.offer {
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 4.15rem;
    gap: 3.2rem;

    li {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    h3 {
      margin-bottom: 0;
      font-size: clamp(18px, 0.8693rem + 1.1364vi, 28px);

      @include media(tablet) {
        font-size: 15px;
      }
    }

    @include media(desktop) {
      grid-template-columns: repeat(3, auto);
      padding: 0;
      gap: 20px;

      @include media(mobile-m) {
        justify-items: center;
        grid-template-columns: 1fr;
      }
    }
  }

  @include media(tablet) {
    img {
      width: 45px;
      height: 45px;
    }
  }
}