@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.team {
  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;

    @include media(mobile-l) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media(mobile-m) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    position: relative;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      background: rgba(255, 243, 249, 0.24);
      opacity: 0;
      transition: opacity 0.4s linear;
      inset: 0;
    }

    img {
      position: relative;
      overflow: hidden;
      border-radius: var(--radius-main);
      width: 100%;
      transition: backdrop-filter 0.4s linear;

      @include media(mobile-m) {
        object-position: center;
        aspect-ratio: 13 / 9;
      }
    }

    &:hover {
      &::before {
        opacity: 1;
      }

      img {
        backdrop-filter: blur(4px);
      }

      .team__descr {
        opacity: 1;
      }
    }
  }

  &__descr {
    position: absolute;
    bottom: 0;
    z-index: 1;
    border-radius: var(--radius-main);
    padding: 24px;
    font-size: 16px;
    color: var(--color-white);
    background-color: var(--color-blue-800);
    opacity: 0;
    transition: opacity 0.4s linear;

    span {
      display: block;
      margin-bottom: 15px;
      font-weight: var(--fw-700);
    }

    p {
      font-weight: var(--fw-400);
    }

    @include media(tablet-wide) {
      padding: 10px;
      font-size: 14px;
    }
  }
}
