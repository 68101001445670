@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.introduction {
  &__content {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, auto);
    gap: 2.4rem;

    img {
      overflow: hidden;
      border-radius: var(--radius-main);
      height: 100%;

      @include media(mobile-l) {
        object-position: center;
        aspect-ratio: 13 / 9;
        margin-inline: auto;
      }
    }

    @include media(tablet) {
      gap: 24px;
    }

    @include media(mobile-l) {
      justify-content: center;
      grid-template-columns: 1fr;
    }
  }

  .sub-text {
    width: 100%;
    max-width: 688px;
    line-height: 1.5;

    @include media(mobile-l) {
      max-width: 100%;
    }
  }
}
