@use "../../utils/functions/"as *;
@use "../../utils/mixins/"as *;

.works {
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.4rem;
    gap: 2.4rem;

    h2 {
      position: relative;
      margin-bottom: 16px;
    }

    @include media(tablet) {
      gap: 5px;

      img {
        width: 45px;
        height: 45px;
      }
    }

    @include media(mobile-m) {
      align-items: flex-start;

      img {
        position: absolute;
        right: 16px;
      }
    }
  }

  &__inner {
    display: grid;
    gap: 2.4rem;

    h3 {
      margin-bottom: 25px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    @include media(mobile-m) {
      grid-template-columns: 1fr;
      gap: 5px;
    }
  }
}