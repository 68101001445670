@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.booking {
  &__list {
    counter-reset: booking-counter;
    display: grid;
    gap: 2.4rem;

    @include media(tablet) {
      gap: 24px;
    }
  }

  &__item {
    counter-increment: booking-counter;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.4rem;

    img {
      border-radius: var(--radius-main);
      height: 100%;
    }

    @include media(tablet) {
      gap: 24px;
    }

    @include media(mobile-m) {
      grid-template-columns: 1fr;

      &:nth-child(odd) {
        img {
          order: 1;
        }
      }
    }
  }

  &__descr {
    &::before {
      content: counter(booking-counter);
      position: relative;
      left: 0;
      display: block;
      margin-bottom: 25px;
      font-weight: 300;
      font-size: clamp(32px, 1.5rem + 1.6667vi, 48px);
      line-height: 1.27;
      color: var(--color-blue-800);
    }

    h3 {
      margin-bottom: 24px;
      font-size: clamp(18px, 0.9946rem + 0.6522vi, 24px);
      line-height: 1;
    }

    p {
      font-weight: 300;
      font-size: 14px;

      &:first-of-type {
        margin-bottom: 21px;
      }
    }
  }
}
