@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.approach {
  $root: &;

  &__list {
    counter-reset: advantages-counter;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  li {
    counter-increment: advantages-counter;
    position: relative;
    border-bottom: 1px solid rgba(44, 46, 53, 0.12);

    &::before {
      content: counter(advantages-counter, decimal-leading-zero);
      position: absolute;
      right: 0;
      bottom: 27px;
      font-size: clamp(20px, 1.5rem + 1.6667vi, 48px);
      line-height: 0.58;
      color: var(--color-blue-800);

      @include media(tablet) {
        position: relative;
        bottom: 0;
      }
    }

    h3 {
      margin-bottom: 8px;
      font-weight: var(--fw-500);
      font-size: 24px;
    }

    p {
      margin-bottom: 12px;
      font-weight: var(--fw-300);
      font-size: 14px;
    }
  }

  @include media(tablet) {
    br {
      display: none;
    }
  }
}