@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.welcome {
  width: 100%;
  max-width: 860px;
  text-align: center;
  margin-inline: auto;

  .btn {
    margin: 0 auto;
  }

  .sub-text {
    margin-bottom: 24px;
    font-weight: var(--fw-500);
  }

  h1 {
    margin-bottom: 24px;
  }
}
