@import "https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap";
:root {
  --content-width: 1320px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Space Grotesk", sans-serif;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --color-dark-900: #2c2e35;
  --color-white: #fff;
  --color-pink-100: #fff3f9;
  --color-pink-400: #efc7db;
  --color-blue-800: #73729a;
  --radius-main: 8px;
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  --radius-big: 36px;
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  letter-spacing: 0;
  color: var(--color-dark-900);
  background-color: var(--color-pink-100);
  font-optical-sizing: auto;
  scroll-padding: var(--header-height);
  font-size: clamp(16px, 1.389vw, 20px);
  font-style: normal;
  line-height: 1.3;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page.open {
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .page {
    --radius-big: 16px;
  }
}

.section {
  padding: 4.3rem 0;
}

@media only screen and (max-width: 992px) {
  .section {
    padding: 2rem 0;
  }
}

.container, .wrapper {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.wrapper {
  max-width: 832px;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.main {
  padding-top: var(--header-height);
}

.logo {
  width: 100%;
  max-width: 260px;
  margin-right: 5.56rem;
}

.logo--footer {
  margin-right: 0 !important;
}

@media only screen and (max-width: 1180px) {
  .logo {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 576px) {
  .logo {
    max-width: 200px;
  }
}

.hidden {
  opacity: 0;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible:focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3 {
  font-weight: var(--fw-700);
  margin-bottom: 1.8rem;
  font-size: clamp(28px, 1.5rem + 1.6667vi, 48px);
  line-height: 1.27;
  overflow: hidden;
}

h2 {
  margin-bottom: 2.4rem;
  font-size: clamp(24px, 1.3333rem + 1.0185vi, 36px);
  line-height: 1.3;
}

h3 {
  font-size: clamp(18px, .9167rem + .9259vi, 28px);
}

.sub-text {
  font-size: clamp(16px, .8571rem + .7143vi, 24px);
}

@media only screen and (max-width: 992px) {
  .sub-text br {
    display: none;
  }
}

.header {
  z-index: 10;
  width: 100%;
  background-color: var(--color-pink-100);
  padding: 20px 0;
  transition-property: transform, box-shadow;
  transition-duration: .15s;
  position: fixed;
  top: 0;
}

.header.hide {
  transform: translateY(-200%);
}

.header.scroll {
  box-shadow: 0 0 16px 0 var(--color-blue-800);
}

@media only screen and (max-width: 992px) {
  .header {
    padding: 10px 0;
  }

  .header .nav__inner {
    z-index: -1;
    padding-top: calc(var(--header-height) * 1.5);
    width: 100%;
    height: calc(var(--vh));
    background-color: var(--color-pink-100);
    flex-direction: column;
    justify-content: flex-start;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }

  .header .nav__list {
    z-index: -1;
    flex-direction: column;
    margin-right: 0;
  }

  .header .nav__item:not(:last-child) {
    margin-right: 0;
  }
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav__inner {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.nav__list {
  align-items: center;
  margin-right: auto;
  display: flex;
}

@media only screen and (max-width: 1180px) {
  .nav__list {
    margin: auto;
  }
}

@media only screen and (max-width: 992px) {
  .nav__list {
    margin: 0;
  }
}

.nav__item:not(:last-of-type) {
  margin-right: 1.6rem;
}

@media only screen and (max-width: 1180px) {
  .nav__item:not(:last-of-type) {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 992px) {
  .nav__item {
    margin-bottom: 30px;
  }
}

.nav__link {
  font-size: 16px;
  line-height: 1.25;
  transition: color .3s linear;
}

.nav__link:hover, .nav__link.true {
  color: var(--color-blue-800);
}

.nav__btns {
  gap: 24px;
  display: flex;
}

.nav__block {
  flex-direction: column;
  gap: 3.4rem;
  display: flex;
}

.nav__block a:not(.logo) {
  font-size: 16px;
  line-height: 1.25;
}

@media only screen and (max-width: 576px) {
  .nav__block {
    gap: 16px;
    margin-bottom: 16px;
  }
}

.nav--footer .nav__list {
  columns: 2;
  gap: 3.5rem;
  margin-right: 13px;
  padding-top: 6px;
  display: block;
}

@media only screen and (max-width: 576px) {
  .nav--footer .nav__list {
    margin: 0;
  }
}

.nav--footer .nav__item:not(:last-of-type) {
  margin: 0 0 26px;
}

@media only screen and (max-width: 576px) {
  .nav--footer {
    flex-direction: column;
    justify-content: center;
  }
}

[class].btn {
  width: 100%;
  max-width: -moz-fit-content;
  max-width: fit-content;
  height: max-content;
  font-weight: var(--fw-700);
  color: var(--color-pink-100);
  background-color: var(--color-blue-800);
  border: 2px solid #0000;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  padding: 8px 22px;
  font-size: 16px;
  line-height: 1.25;
  transition-property: background-color, color, border-color;
  transition-duration: .3s;
  display: flex;
}

[class].btn:hover, [class].btn:focus {
  border-color: var(--color-blue-800);
  color: var(--color-blue-800);
  background-color: var(--color-pink-100);
}

[class].btn.formsapp-button {
  font-family: var(--font-family-primary);
  margin: 0;
  background-color: var(--color-blue-800) !important;
}

[class].btn.formsapp-button:hover, [class].btn.formsapp-button:focus {
  border-color: var(--color-blue-800) !important;
  color: var(--color-blue-800) !important;
  background-color: var(--color-pink-100) !important;
}

[class].btn--reverse {
  border-color: var(--color-blue-800);
  color: var(--color-blue-800);
  background-color: var(--color-pink-100);
}

[class].btn--reverse:hover, [class].btn--reverse:focus {
  color: var(--color-pink-100);
  background-color: var(--color-blue-800);
}

[class].btn--big {
  max-width: 190px;
}

[class].btn--slider {
  z-index: 2;
  color: var(--color-white);
  height: 32px;
  max-width: 32px;
  background-color: #0000;
  border: 0 #0000;
  border-radius: 50%;
  padding: 0;
  position: relative;
}

[class].btn--slider:before {
  content: "";
  width: 32px;
  height: 32px;
  background-color: currentColor;
  background-color: var(--color-dark-900);
  transition: background-color .4s linear;
  position: absolute;
  -webkit-mask-image: url("../images/icons/arrow.svg");
  mask-image: url("../images/icons/arrow.svg");
  -webkit-mask-position: 0 0;
  mask-position: 0 0;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

[class].btn--slider:hover, [class].btn--slider:focus {
  border-color: #0000;
}

[class].btn--slider:hover:before, [class].btn--slider:focus:before {
  background-color: var(--color-blue-800);
}

.burger {
  --line-height: 2px;
  --burger-size: 20px;
  --move: calc(var(--burger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-blue-800);
  width: calc(var(--burger-size)  + 5px);
  height: var(--burger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(-5%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.policy {
  padding: 4.3rem 0;
}

.policy__content:not(:last-child) {
  margin-bottom: 16px;
}

.policy h1 {
  margin-bottom: 2.4rem;
  font-size: clamp(26px, 1.225rem + 2vi, 48px);
}

.policy h2 {
  font-weight: var(--fw-600);
  margin-bottom: 16px;
  font-size: clamp(18px, 1.05rem + .375vi, 24px);
}

.policy ul {
  margin-top: 20px;
  margin-bottom: 30px;
  padding-left: 15px;
}

.policy li {
  letter-spacing: .02em;
  padding-left: 10px;
}

.policy li:not(:last-child) {
  margin-bottom: 3px;
}

.policy li::marker {
  content: "✓";
}

.policy p {
  line-height: 1.2;
}

.policy a {
  color: var(--color-blue-800);
  text-decoration: underline;
}

@media only screen and (max-width: 992px) {
  .policy {
    padding: 2rem 0;
  }
}

.footer__inner {
  padding: 1.85rem 0;
}

.footer__top {
  padding-bottom: 1.7rem;
  position: relative;
}

.footer__top:before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #2c2e351f;
  position: absolute;
  bottom: 10px;
}

.footer__bottom {
  justify-content: space-between;
  align-items: center;
  padding-top: 25px;
  font-size: 16px;
  display: flex;
}

@media only screen and (max-width: 576px) {
  .footer__bottom {
    text-align: center;
    flex-direction: column;
    gap: 16px;
  }
}

.footer__list {
  justify-content: space-between;
  gap: 16px;
  display: flex;
}

.footer__item:not(:last-child) {
  margin-right: 1.6rem;
}

@media only screen and (max-width: 768px) {
  .footer__item:not(:last-child) {
    margin-right: 16px;
  }
}

@media only screen and (max-width: 576px) {
  .footer__item:not(:last-child) {
    margin: 0;
  }
}

.footer__link {
  transition: color .3s linear;
}

.footer__link:hover {
  color: var(--color-blue-800);
}

.news__list {
  grid-template-columns: repeat(4, 1fr);
  gap: 2.4rem;
  margin-bottom: 2.4rem;
  display: grid;
}

@media only screen and (max-width: 768px) {
  .news__list {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
}

@media only screen and (max-width: 576px) {
  .news__list {
    grid-template-columns: 1fr;
  }
}

.news__cards {
  position: relative;
}

.news__cards img {
  border-radius: var(--radius-main);
  width: 100%;
  margin-bottom: 18px;
  overflow: hidden;
}

@media only screen and (max-width: 576px) {
  .news__cards img {
    aspect-ratio: 16 / 9;
  }
}

.news__cards h3 {
  margin-bottom: 7px;
  font-size: clamp(16px, 1.0833rem + .1852vi, 20px);
  transition: color .3s linear;
}

.news__cards p {
  font-size: 14px;
}

.news__cards a:before {
  content: "";
  position: absolute;
  inset: 0;
}

.news__cards:hover h3 {
  color: var(--color-blue-800);
}

.news--main {
  padding-top: 2.4rem;
}

.news .btn {
  margin: 0 auto;
}

.news h1 {
  text-align: center;
  text-transform: capitalize;
  margin-inline: auto;
}

.news h2 {
  text-align: center;
  margin-bottom: 2.3rem;
}

.contact {
  padding: 1.8rem 0;
}

.contact__content {
  margin-bottom: 2.4rem;
}

.contact__inner {
  border-radius: var(--radius-big);
  width: 100%;
  max-width: 1100px;
  text-align: center;
  color: var(--color-white);
  background-color: var(--color-blue-800);
  margin-inline: auto;
  padding: 3rem 1rem;
  box-shadow: 0 0 60px #bcf7ff1f;
}

.contact__inner h2 {
  margin-bottom: 16px;
}

.contact__inner p {
  line-height: 1;
}

.contact__form {
  width: 100%;
  max-width: 556px;
  margin-inline: auto;
}

.contact__form label {
  text-align: left;
  display: block;
}

.contact__form label span {
  margin-bottom: 6px;
  font-size: 16px;
  line-height: 1.75;
  display: block;
}

.contact__form label:not(:last-of-type) {
  margin-bottom: 24px;
}

.contact__form label:last-of-type {
  margin-bottom: 18px;
}

.contact__form textarea, .contact__form input {
  width: 100%;
  height: 42px;
  color: var(--color-white);
  background-color: var(--color-blue-800);
  border: 1px solid #fff3f95c;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 1.2;
}

.contact__form textarea::placeholder, .contact__form input::placeholder {
  color: #fff3f95c;
}

.contact__form textarea {
  height: 96px;
  resize: none;
}

.contact .btn {
  max-width: 109px;
  margin-inline: auto;
}

.contact .btn:hover, .contact .btn:focus {
  background-color: var(--color-pink-400);
}

.article {
  padding: 2.4rem 0 4.3rem;
}

.article__head {
  min-height: 415px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 2.8rem;
}

@media only screen and (max-width: 768px) {
  .article__head {
    min-height: 350px;
    margin-bottom: 1.5rem;
  }
}

@media only screen and (max-width: 576px) {
  .article__head {
    min-height: 200px;
  }
}

.article__descr {
  width: 100%;
  max-width: 800px;
  margin: 0 auto 16px;
}

.article__descr h2 {
  text-align: center;
  margin-bottom: 16px;
}

.article img {
  border-radius: var(--radius-main);
  margin-bottom: 24px;
  overflow: hidden;
}

.article h1 {
  text-align: center;
  text-transform: capitalize;
  margin: 0 auto 1.8rem;
  padding: 0 16px;
}

.article h2, .article p {
  letter-spacing: .02em;
  margin-bottom: 24px;
}

.article p {
  margin-bottom: 36px;
}

.article p:last-of-type {
  margin-bottom: 0;
}

.article h2 {
  font-size: clamp(18px, 1rem + .5556vi, 24px);
  line-height: 1.67;
}

.article ul {
  margin-bottom: 36px;
  padding-left: 15px;
}

.article li {
  letter-spacing: .02em;
  list-style-type: disc;
}

@media only screen and (max-width: 768px) {
  .article {
    padding: 1.4rem 0 2.3rem;
  }

  .article br {
    display: none;
  }
}

.hero {
  margin-inline: auto;
  padding: 2.4rem 0;
}

.hero h1 {
  text-align: center;
}

.hero__descr p {
  font-weight: var(--fw-500);
  text-align: center;
  margin-inline: auto;
}

.hero--services h1 {
  margin-bottom: 24px;
}

.gallery {
  min-height: 821px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 1180px) {
  .gallery {
    min-height: 400px;
    background-size: cover;
  }
}

@media only screen and (max-width: 576px) {
  .gallery {
    min-height: 250px;
  }
}

.portfolio__masonry {
  columns: 3;
  gap: 2.4rem;
  margin-bottom: 2.45rem;
}

.portfolio__masonry * {
  break-inside: avoid;
  margin-bottom: 24px;
}

@media only screen and (max-width: 992px) {
  .portfolio__masonry {
    gap: 16px;
  }

  .portfolio__masonry * {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .portfolio__masonry {
    columns: 1;
  }
}

.portfolio__list {
  columns: 2;
  gap: 16px;
}

@media only screen and (max-width: 768px) {
  .portfolio__list {
    columns: 4;
  }
}

.portfolio__item {
  border-radius: var(--radius-main);
}

.portfolio__item h3 {
  text-align: center;
  font-size: clamp(18px, 1.0835rem + .185vi, 20px);
}

@media only screen and (max-width: 576px) {
  .portfolio__item h3 {
    font-size: 11px;
  }
}

.portfolio .btn {
  margin-inline: auto;
}

.portfolio h2 {
  text-align: center;
  margin-bottom: 2.3rem;
}

@media only screen and (max-width: 992px) {
  .portfolio br {
    display: none;
  }
}

.welcome {
  width: 100%;
  max-width: 860px;
  text-align: center;
  margin-inline: auto;
}

.welcome .btn {
  margin: 0 auto;
}

.welcome .sub-text {
  font-weight: var(--fw-500);
  margin-bottom: 24px;
}

.welcome h1 {
  margin-bottom: 24px;
}

.projects__inner {
  grid-template-columns: repeat(2, auto);
  gap: 2.85rem;
  display: grid;
}

@media only screen and (max-width: 992px) {
  .projects__inner {
    grid-template-columns: 1fr;
    gap: 30px;
  }
}

.projects__content {
  width: 100%;
  max-width: 442px;
  transform: translateY(35px);
}

.projects__content .sub-text {
  font-weight: var(--fw-300);
  margin-bottom: 24px;
}

@media only screen and (max-width: 992px) {
  .projects__content {
    max-width: 100%;
    transform: none;
  }

  .projects {
    padding: 2rem 0;
  }
}

.service__top {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.25rem;
  display: flex;
}

.service__top h2 {
  margin-bottom: 0;
}

@media only screen and (max-width: 576px) {
  .service__top {
    flex-direction: column;
    gap: 24px;
  }
}

.service__grid {
  grid-template-columns: repeat(2, auto);
  gap: 2.4rem;
  display: grid;
}

@media only screen and (max-width: 1280px) {
  .service__grid {
    gap: 24px;
  }
}

@media only screen and (max-width: 1180px) {
  .service__grid {
    grid-template-columns: 1fr;
  }
}

.service__item {
  border: 1px solid var(--color-dark-900);
  height: max-content;
  border-radius: 8px;
}

.service__item img {
  border-radius: 8px;
}

.service__item:not(:first-child) {
  gap: 10px;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .service__item:not(:first-child) {
    flex-direction: column;
  }

  .service__item:not(:first-child) img {
    width: 100%;
  }
}

.service__item:first-child {
  max-width: 566px;
  grid-row: 2 span;
}

.service__item:first-child .service__descr {
  margin-bottom: 1.8rem;
  padding: 27px 34px 0;
}

@media only screen and (max-width: 768px) {
  .service__item:first-child .service__descr {
    padding: 16px;
  }
}

.service__item:first-child img {
  border-radius: 0 0 8px 8px;
}

@media only screen and (max-width: 1280px) {
  .service__item:first-child {
    align-self: center;
  }
}

@media only screen and (max-width: 1180px) {
  .service__item:first-child {
    max-width: 100%;
    grid-row: auto;
  }

  .service__item:first-child img {
    width: 100%;
  }
}

.service__item:last-child img {
  border-radius: 8px 0 0 8px;
}

.service__item:last-child .service__descr {
  padding: 38px 0 0 24px;
}

@media only screen and (max-width: 768px) {
  .service__item:last-child .service__descr {
    padding: 16px;
  }

  .service__item:last-child {
    flex-direction: column-reverse;
  }
}

.service__descr {
  padding: 26px 0 22px 36px;
}

@media only screen and (max-width: 768px) {
  .service__descr {
    padding: 16px;
  }
}

.service .btn {
  max-width: 190px;
}

.service h3 {
  margin-bottom: 24px;
}

@media only screen and (max-width: 1180px) {
  .service br {
    display: none;
  }
}

.reviews__inner {
  align-items: flex-end;
  gap: 24px;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .reviews__inner {
    flex-direction: column;
    align-items: center;
  }
}

.reviews__slider {
  width: 100%;
  max-width: 783px;
  position: relative;
  overflow: hidden;
}

.reviews__item {
  min-height: 518px;
  gap: 2.4rem;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .reviews__item {
    min-height: 250px;
    gap: 24px;
  }

  .reviews__item img {
    aspect-ratio: 12 / 9;
  }
}

@media only screen and (max-width: 475px) {
  .reviews__item {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.reviews__descr {
  align-self: center;
  transform: translateY(-23px);
}

.reviews__descr p {
  line-height: 1;
}

@media only screen and (max-width: 768px) {
  .reviews__descr {
    padding-bottom: 16px;
    transform: none;
  }
}

.reviews__autor {
  align-items: center;
  gap: 13px;
  margin-bottom: 23px;
  display: flex;
}

@media only screen and (max-width: 475px) {
  .reviews__autor {
    justify-content: center;
  }
}

.reviews__btns {
  z-index: 2;
  gap: 46px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 34%;
  transform: translateX(30%);
}

@media only screen and (max-width: 992px) {
  .reviews__btns {
    left: 70%;
    transform: translateX(70%);
  }
}

@media only screen and (max-width: 768px) {
  .reviews__btns {
    transform: translateX(20%);
  }
}

@media only screen and (max-width: 475px) {
  .reviews__btns {
    justify-content: center;
    gap: 24px;
    position: relative;
    left: 0;
    transform: none;
  }
}

.reviews h3 {
  font-weight: var(--fw-500);
  margin-bottom: 0;
  font-size: clamp(16px, .8333rem + .7407vi, 24px);
  line-height: 1;
}

.reviews h2 {
  margin-bottom: 7rem;
}

@media only screen and (max-width: 768px) {
  .reviews h2 {
    margin-bottom: 2rem;
  }
}

.reviews .btn.swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

.reviews .btn--prev {
  scale: -1 1;
}

.faq__item {
  border-radius: var(--radius-main);
  box-shadow: 0 0 0 2px #2c2e351f;
}

.faq__item:not(:last-child) {
  margin-bottom: 1.6rem;
}

.faq details {
  border-radius: var(--radius-main);
  overflow: hidden;
}

.faq details p {
  letter-spacing: .02em;
}

.faq details[open] summary {
  padding-bottom: 2px;
}

.faq details[open] summary:after {
  transform: scaleY(-1);
}

.faq summary {
  font-weight: var(--fw-700);
  letter-spacing: .02em;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 24px 48px 24px 32px;
  line-height: 1.3;
  display: flex;
}

.faq summary:after {
  content: "";
  min-width: 32px;
  min-height: 32px;
  background-color: currentColor;
  background-color: var(--color-dark-900);
  transition: transform .3s linear;
  display: block;
  position: absolute;
  right: 30px;
  -webkit-mask-image: url("../images/icons/chevron-down.svg");
  mask-image: url("../images/icons/chevron-down.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.faq summary + div {
  padding: 1.7rem 1.6rem 21px 30px;
}

.faq h2 {
  margin-bottom: 2.4rem;
}

.team__list {
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  display: grid;
}

@media only screen and (max-width: 768px) {
  .team__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 576px) {
  .team__list {
    grid-template-columns: 1fr;
  }
}

.team__item {
  cursor: pointer;
  position: relative;
}

.team__item:before {
  content: "";
  z-index: 1;
  opacity: 0;
  background: #fff3f93d;
  transition: opacity .4s linear;
  position: absolute;
  inset: 0;
}

.team__item img {
  border-radius: var(--radius-main);
  width: 100%;
  transition: -webkit-backdrop-filter .4s linear, backdrop-filter .4s linear;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 576px) {
  .team__item img {
    object-position: center;
    aspect-ratio: 13 / 9;
  }
}

.team__item:hover:before {
  opacity: 1;
}

.team__item:hover img {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.team__item:hover .team__descr {
  opacity: 1;
}

.team__descr {
  z-index: 1;
  border-radius: var(--radius-main);
  color: var(--color-white);
  background-color: var(--color-blue-800);
  opacity: 0;
  padding: 24px;
  font-size: 16px;
  transition: opacity .4s linear;
  position: absolute;
  bottom: 0;
}

.team__descr span {
  font-weight: var(--fw-700);
  margin-bottom: 15px;
  display: block;
}

.team__descr p {
  font-weight: var(--fw-400);
}

@media only screen and (max-width: 1180px) {
  .team__descr {
    padding: 10px;
    font-size: 14px;
  }
}

.introduction__content {
  grid-template-columns: repeat(2, auto);
  align-items: center;
  gap: 2.4rem;
  display: grid;
}

.introduction__content img {
  border-radius: var(--radius-main);
  height: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .introduction__content img {
    object-position: center;
    aspect-ratio: 13 / 9;
    margin-inline: auto;
  }
}

@media only screen and (max-width: 992px) {
  .introduction__content {
    gap: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .introduction__content {
    grid-template-columns: 1fr;
    justify-content: center;
  }
}

.introduction .sub-text {
  width: 100%;
  max-width: 688px;
  line-height: 1.5;
}

@media only screen and (max-width: 768px) {
  .introduction .sub-text {
    max-width: 100%;
  }
}

.history__inner {
  grid-template-columns: repeat(2, auto);
  gap: 2.4rem;
  display: grid;
}

@media only screen and (max-width: 1280px) {
  .history__inner {
    gap: 24px;
  }
}

@media only screen and (max-width: 992px) {
  .history__inner {
    grid-template-columns: 1fr;
  }
}

.history__image {
  border-radius: var(--radius-main);
  justify-self: center;
  overflow: hidden;
}

.history__image img {
  height: 100%;
}

@media only screen and (max-width: 576px) {
  .history__image img {
    object-position: center;
    aspect-ratio: 13 / 9;
  }
}

.history__descr {
  width: 100%;
  max-width: 581px;
  line-height: 1.5;
}

.history__descr li:not(:last-child) {
  margin-bottom: 1.8rem;
}

.history__descr h3 {
  margin-bottom: 15px;
  font-size: clamp(16px, .8571rem + .7143vi, 24px);
  line-height: 1.5;
}

@media only screen and (max-width: 992px) {
  .history__descr {
    max-width: 100%;
  }

  .history__descr br {
    display: none;
  }
}

.approach__list {
  counter-reset: advantages-counter;
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.approach li {
  counter-increment: advantages-counter;
  border-bottom: 1px solid #2c2e351f;
  position: relative;
}

.approach li:before {
  content: counter(advantages-counter, decimal-leading-zero);
  color: var(--color-blue-800);
  font-size: clamp(20px, 1.5rem + 1.6667vi, 48px);
  line-height: .58;
  position: absolute;
  bottom: 27px;
  right: 0;
}

@media only screen and (max-width: 992px) {
  .approach li:before {
    position: relative;
    bottom: 0;
  }
}

.approach li h3 {
  font-weight: var(--fw-500);
  margin-bottom: 8px;
  font-size: 24px;
}

.approach li p {
  font-weight: var(--fw-300);
  margin-bottom: 12px;
  font-size: 14px;
}

@media only screen and (max-width: 992px) {
  .approach br {
    display: none;
  }
}

.solutions__grid {
  grid-template-columns: repeat(2, auto);
  align-items: center;
  gap: 2.4rem;
  display: grid;
}

.solutions__grid:not(:last-of-type) {
  margin-bottom: 2.4rem;
}

@media only screen and (max-width: 1280px) {
  .solutions__grid {
    gap: 20px;
  }
}

@media only screen and (max-width: 1180px) {
  .solutions__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 992px) {
  .solutions__grid {
    grid-template-columns: 1fr;
  }
}

.solutions__image {
  border-radius: 0 0 8px 8px;
  overflow: hidden;
}

.solutions__image img {
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .solutions__image img {
    aspect-ratio: 16 / 9;
  }
}

.solutions__list {
  width: 100%;
  max-width: 672px;
}

@media only screen and (max-width: 992px) {
  .solutions__list {
    max-width: 100%;
  }
}

.solutions__item h3 {
  margin-bottom: 0;
  font-size: clamp(18px, .9167rem + .9259vi, 28px);
}

.solutions__item span {
  font-weight: var(--fw-700);
  font-size: clamp(18px, .9167rem + .9259vi, 28px);
}

.solutions__item:first-of-type {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.solutions__item:not(:last-of-type) {
  border-bottom: 1px solid #2c2e351f;
  margin-bottom: 19px;
  padding-bottom: 14px;
}

.advantages {
  padding: 5.3rem 0;
}

.advantages__list {
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 30px;
  display: grid;
}

@media only screen and (max-width: 768px) {
  .advantages__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

.advantages li {
  text-align: center;
  font-size: clamp(16px, .8571rem + .7143vi, 24px);
}

.advantages li:not(:last-of-type) {
  position: relative;
}

.advantages li:not(:last-of-type):before {
  content: "";
  width: 1px;
  height: 230px;
  background: #2c2e351f;
  position: absolute;
  top: 50%;
  right: -15px;
  transform: translateY(-50%);
}

@media only screen and (max-width: 992px) {
  .advantages li:not(:last-of-type):before {
    height: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .advantages li:last-child {
    grid-column: 2 span;
  }
}

@media only screen and (max-width: 992px) {
  .advantages {
    padding: 2.5rem 0;
  }

  .advantages br {
    display: none;
  }
}

.booking__list {
  counter-reset: booking-counter;
  gap: 2.4rem;
  display: grid;
}

@media only screen and (max-width: 992px) {
  .booking__list {
    gap: 24px;
  }
}

.booking__item {
  counter-increment: booking-counter;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 2.4rem;
  display: grid;
}

.booking__item img {
  border-radius: var(--radius-main);
  height: 100%;
}

@media only screen and (max-width: 992px) {
  .booking__item {
    gap: 24px;
  }
}

@media only screen and (max-width: 576px) {
  .booking__item {
    grid-template-columns: 1fr;
  }

  .booking__item:nth-child(2n+1) img {
    order: 1;
  }
}

.booking__descr:before {
  content: counter(booking-counter);
  color: var(--color-blue-800);
  margin-bottom: 25px;
  font-size: clamp(32px, 1.5rem + 1.6667vi, 48px);
  font-weight: 300;
  line-height: 1.27;
  display: block;
  position: relative;
  left: 0;
}

.booking__descr h3 {
  margin-bottom: 24px;
  font-size: clamp(18px, .9946rem + .6522vi, 24px);
  line-height: 1;
}

.booking__descr p {
  font-size: 14px;
  font-weight: 300;
}

.booking__descr p:first-of-type {
  margin-bottom: 21px;
}

.offer__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 3.2rem;
  padding: 0 4.15rem;
  display: grid;
}

.offer__list li {
  align-items: center;
  gap: 16px;
  display: flex;
}

.offer__list h3 {
  margin-bottom: 0;
  font-size: clamp(18px, .8693rem + 1.1364vi, 28px);
}

@media only screen and (max-width: 992px) {
  .offer__list h3 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1280px) {
  .offer__list {
    grid-template-columns: repeat(3, auto);
    gap: 20px;
    padding: 0;
  }
}

@media only screen and (max-width: 1280px) and (max-width: 576px) {
  .offer__list {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

@media only screen and (max-width: 992px) {
  .offer img {
    width: 45px;
    height: 45px;
  }
}

.works__content {
  justify-content: space-between;
  align-items: center;
  gap: 2.4rem;
  margin-bottom: 2.4rem;
  display: flex;
}

.works__content h2 {
  margin-bottom: 16px;
  position: relative;
}

@media only screen and (max-width: 992px) {
  .works__content {
    gap: 5px;
  }

  .works__content img {
    width: 45px;
    height: 45px;
  }
}

@media only screen and (max-width: 576px) {
  .works__content {
    align-items: flex-start;
  }

  .works__content img {
    position: absolute;
    right: 16px;
  }
}

.works__inner {
  gap: 2.4rem;
  display: grid;
}

.works__inner h3 {
  margin-bottom: 25px;
}

.works__list {
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  display: grid;
}

@media only screen and (max-width: 576px) {
  .works__list {
    grid-template-columns: 1fr;
    gap: 5px;
  }
}

.collage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10.4rem 0 8.2rem;
}

@media only screen and (max-width: 992px) {
  .collage {
    padding: 4rem;
  }
}

/*# sourceMappingURL=main.css.map */
